import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import {
    apiGetPipeLineList,
    apiGetWorkLocationList,
} from 'services/GeneralService'
import { makeDynamicFields } from 'utils/factory'
export const initialState = {
    workLocations: {
        workLocationList: [{ name: '', details: '' }],
        workLocationOptions: [],
        workLocationLoading: false,
    },

    pipeLines: {
        pipeLineList: [{ name: '', details: '' }],
        pipeLineOptions: [],
        pipeLineLoading: false,
    },

    error: null,
}

export const SLICE_NAME = 'Generals'

export const getPipeLineListAction = createAsyncThunk(
    'get/PipeLine',
    async () => {
        const { data } = await apiGetPipeLineList()
        let pipeLineList
        pipeLineList = makeDynamicFields({
            rawData: data,
            pick: ['name', 'details'],
            path: 'WorkLocation',
        })

        const pipeLineOptions = makeDynamicFields({
            rawData: data,
            pick: ['name', 'uuid', 'id'],
            drop: ['label', 'value', 'id'],
            path: 'WorkLocation',
        })

        return {
            data: [pipeLineList, pipeLineOptions],
        }
    }
)

export const getWorkLocationListAction = createAsyncThunk(
    'get/WorkLocation',
    async () => {
        const { WorkLocations } = await apiGetWorkLocationList()
        let workLocationList
        workLocationList = makeDynamicFields({
            rawData: WorkLocations,
            pick: ['name', 'details'],
            path: 'WorkLocation',
        })

        const workLocationOptions = makeDynamicFields({
            rawData: WorkLocations,
            pick: ['name', 'uuid'],
            drop: ['label', 'value'],
            path: 'WorkLocation',
        })

        return {
            data: [workLocationList, workLocationOptions],
        }
    }
)

const generalSlice = createSlice({
    name: `${SLICE_NAME}/state`,
    initialState,
    reducers: {
        updateWorkLocation(state, action) {
            const workLocationList = action.payload
            state.workLocations = {
                ...state.workLocations,
                workLocationList:
                    workLocationList || state.workLocations.workLocationList,
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getPipeLineListAction.pending, (state) => {
                state.pipeLines.pipeLineLoading = true
            })
            .addCase(getPipeLineListAction.fulfilled, (state, { payload }) => {
                const { data } = payload
                const [pipeLineList, pipeLineOptions] = data
                state.pipeLines.pipeLineList = pipeLineList
                state.pipeLines.pipeLineOptions = pipeLineOptions
                state.pipeLines.pipeLineLoading = false
            })

            .addCase(getPipeLineListAction.rejected, (state, action) => {
                state.pipeLines.pipeLineLoading = false
                state.error =
                    action?.error?.message ??
                    'An error occurred in get state benefits Package Loading List Action'
            })
    },
})

export const {} = generalSlice.actions
export const generalSelector = (state) => state.general

export default generalSlice.reducer
