export const ROOT = '/'
export const ADMIN_PREFIX_PATH = '/admin'
export const APP_PREFIX_PATH = '/app'
export const AUTH_PREFIX_PATH = '/auth'
export const UI_COMPONENTS_PREFIX_PATH = '/ui-components'
export const PAGES_PREFIX_PATH = '/pages'
export const DOCS_PREFIX_PATH = '/docs'

export const ORGANIZATION_PREFIX_PATH = '/organization'
export const COMMUNICATION_PREFIX_PATH = 'communication'

export const CHART_PREFIX_PATH = '/chart'

export const EMPLOYEE_ROUTE_PREFIX = 'employee'
export const DASHBOARD_ROUTE_PREFIX = 'dashboard'
export const ASSET_ROUTE_PREFIX = 'assets'
export const SETTING_ROUTE_PREFIX = 'setting'
export const POLICY_ROUTE_PREFIX = 'policy'
export const MEMBERSHIP_ROUTE_PREFIX = 'membership'
export const GIVEAWAY_ROUTE_PREFIX = 'giveaways'
