import { COUPONS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const createCouponsTable = () => supabase.from(COUPONS_TABLE)

export const addCouponApi = async (payload) => {
    const couponsTable = createCouponsTable()
    const addCouponQuery = couponsTable.insert(payload).select().single()
    const CouponData = await executeQuery(addCouponQuery)
    return CouponData
}

export const apiGetCoupons = async (payload) => {
    const couponsTable = createCouponsTable()
    const {
        pageSize = 10,
        pageIndex = 1,
        query = '',
        filterColumns,
        sort,
    } = payload

    const filters = payload?.filters || []

    const from = (pageIndex - 1) * pageSize
    const to = from + pageSize - 1

    let couponsQuery = couponsTable
        .select('*', { count: 'exact' })
        .eq('is_delete', false)

    if (sort && sort.key !== '' && sort.key !== null) {
        couponsQuery = couponsQuery.order(sort.key, {
            ascending: sort.order === 'asc' ? true : false,
        })
    }

    if (query) {
        filterColumns.forEach((column) => {
            couponsQuery = couponsQuery.ilike(column, `%${query}%`)
        })
    }

    if (Array.isArray(filters) && filters.length > 0) {
        filters.forEach(({ key, value }) => {
            if (value != null) {
                couponsQuery = couponsQuery.eq(key, value)
            }
        })
    }

    couponsQuery = couponsQuery.range(from, to)

    const result = await executeQuery(couponsQuery)

    if (result.error) {
        throw result.error
    }

    return {
        data: result.data,
        count: result.count,
        message: 'Coupons Data Fetched Successfully',
    }
}

export const editCouponApi = async (couponId, payload) => {
    const couponsTable = createCouponsTable()
    const editCouponQuery = couponsTable
        .update(payload)
        .eq('id', couponId)
        .select()
        .single()
    const CouponData = await executeQuery(editCouponQuery)
    return CouponData
}

export const changeCouponStatusApi = async (couponId, status) => {
    const couponsTable = createCouponsTable()
    const deleteCouponQuery = couponsTable
        .update({ status })
        .eq('id', couponId)
        .select()
        .single()
    const CouponData = await executeQuery(deleteCouponQuery)
    return CouponData
}

export const deleteCouponApi = async (couponId) => {
    const couponsTable = createCouponsTable()
    const deleteCouponQuery = couponsTable
        .update({ is_delete: true })
        .eq('id', couponId)
        .select()
    const CouponData = await executeQuery(deleteCouponQuery)
    return CouponData
}

export const getCouponByUUIDApi = async (uuid) => {
    const couponsTable = createCouponsTable()
    const fetchDataQuery = couponsTable.select('*').eq('id', uuid).single()
    const CouponData = await executeQuery(fetchDataQuery)
    return CouponData
}

export const getCouponsStatsApi = async () => {
    const { data, error } = await supabase.rpc('get_coupon_stats')

    return { data, error, message: '' }
}
