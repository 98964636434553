import { executeQuery } from 'utils/executeQuery'
import supabase from 'supabase'
import { PARTNER_DEALS_TABLE, PARTNERS_TABLE } from 'constants/supabase.contant'
import { apiAddOffer } from './OfferService'
import { apiAddPartnersCategories } from './PartnersCategoriesService'
import { apiAddOfferMemberships } from './OfferMembershipsService'
import { apiAddPartnerSocials } from './PartnerSocialsServices'

const partnerTable = supabase.from(PARTNERS_TABLE)

const partnerPayload = {
    partner_name: null,
    image: null,
    description_html: null,
    partner_email: null,
    partner_phone: null,
    term_condition_html: null,
    documents: [],
    brand_page_html: null,
}

const partner = {
    partner: {
        partner_name: null,
        image: null,
        description_html: null,
        partner_email: null,
        partner_phone: null,
        term_condition_html: null,
        documents: [],
        brand_page_html: null,
    },
    categories: ['categoy_id'],
    offer: {
        description_html: null,
        redeem_code: null,
        redeem_url: null,
        usage: null,
    },
    memberships: ['membership_id'],
    socials: [{ type: 'facebook', url: '' }],
}

export const apiAddNewPartner = async (payload) => {
    let { partner, categories, offer, memberships, socials } = payload
    const addPartnerQuery = partnerTable.insert(partner).select().single()
    const partnerData = await executeQuery(addPartnerQuery)
    const partner_id = partnerData?.data?.id
    categories = categories?.map((item) => ({
        partner_id,
        category_id: item,
    }))
    memberships = memberships?.map((item) => ({
        membership_id: item,
        partner_id,
    }))
    socials = socials?.map((item) => ({ ...item, partner_id }))
    await Promise.allSettled([
        await apiAddOffer({ ...offer, partner_id }),
        await apiAddPartnersCategories(categories),
        await apiAddOfferMemberships(memberships),
        await apiAddPartnerSocials(socials),
    ])

    return partnerData
}

export const apiUpdatePartner = async (partnerId, payload) => {
    const editPartnerQuery = partnerTable
        .update(payload)
        .eq('id', partnerId)
        .select()
    const partnerData = await executeQuery(editPartnerQuery)
    return partnerData
}

export const apiGetpartner = async (partnerId) => {
    const partnerDataQuery = partnerTable
        .select(
            `*,partner_socials(*),partners_categories(*,categories(*)),offers(*,offers_memberships(*,memberships(*)))`
        )
        .eq('id', partnerId)
        .single()
    const partnerDetail = await executeQuery(partnerDataQuery)
    return partnerDetail
}

export const apiGetAllPartners = async (payload) => {
    const {
        pageIndex = 1,
        pageSize = 1000,
        search_name = null,
        membership_id_param = null,
        offer_status_param = 'active',
    } = payload

    const getAllPartnerQuery = supabase.rpc('get_filtered_partners', {
        membership_id_param,
        search_name,
        offer_status_param,
        page_size: pageSize,
        page: pageIndex,
    })

    const getAllPartnerData = await executeQuery(getAllPartnerQuery)
    return getAllPartnerData
}
