import { CATEGORIES_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const categoriesTable = supabase.from(CATEGORIES_TABLE)

export const apiGetCategories = async () => {
    const getCategoriesQuery = categoriesTable.select('*')
    const categoriesData = executeQuery(getCategoriesQuery)
    return categoriesData
}
