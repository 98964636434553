import { OFFERS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const offerTable = supabase.from(OFFERS_TABLE)

const offerPayload = {
    id: null,
    description_html: null,
    redeem_code: null,
    redeem_url: null,
    usage: null,
    partner_id: null,
}

export const apiAddOffer = async (payload) => {
    const addOfferQuery = offerTable.insert(payload)
    const addOfferData = await executeQuery(addOfferQuery)
    return addOfferData
}

export const apiEditOffer = async (offerId, payload) => {
    const updateOfferQuery = offerTable.update(payload).eq('id', offerId)
    const updateOfferData = await executeQuery(updateOfferQuery)
    return updateOfferData
}
