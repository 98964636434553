import React, { createContext, useContext, useRef, useState } from 'react'

const GlobalHeaderContext = createContext()

export const GlobalHeaderProvider = ({ children }) => {
    const listenersRef = useRef(new Set())

    const [breadcrumbs, setBreadcrumbs] = useState([])
    const [breadcrumbsLoading, setBreadcrumbsLoading] = useState(false)

    const [headerLoading, setHeaderLoading] = useState(false)
    const [heading, setHeading] = useState('')

    const registerListener = (listener) => {
        listenersRef.current.add(listener)
    }
    const unregisterListener = (listener) => {
        listenersRef.current.delete(listener)
    }
    const notifyListeners = () => {
        listenersRef.current.forEach((listener) => listener())
    }
    return (
        <GlobalHeaderContext.Provider
            value={{
                registerListener,
                unregisterListener,
                notifyListeners,
                breadcrumbs,
                setBreadcrumbs,
                breadcrumbsLoading,
                setBreadcrumbsLoading,
                headerLoading,
                setHeaderLoading,
                heading,
                setHeading,
            }}
        >
            {children}
        </GlobalHeaderContext.Provider>
    )
}

export const useGlobalHeader = () => useContext(GlobalHeaderContext)
