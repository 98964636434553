// import { ADMIN_PREFIX_PATH } from 'constants/route.constant'

const appConfig = {
    apiPrefix: process.env.REACT_APP_API_BASE_URL,
    authenticatedEntryPath: '/dashboard',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/app/account/kyc-form',
    locale: 'en',
    enableMock: false,
}

export default appConfig
