export const DEFAULT_CURRENCY = '£'
export const DISCOUNT_TYPE = '%'

export const SUBSCRIPTION_STAUTS_OPTIONS = [
    { value: 'active', label: 'Active' },
    { value: 'completed', label: 'Completed' },
    { value: 'cancelled', label: 'Cancelled' },
    { value: 'non-renewing', label: 'Non Renew' },
    // { value: 'attention', label: 'Attention' },
]

export const PAYSTACK_SUBSCRIPTION_STATUS = {
    Active: 'active',
    Completed: 'completed',
    Cancelled: 'cancelled',
    Non_Renew: 'non-renewing',
    Attention: 'attention',
}
