import { PARTNERS_CATEGORIES_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'
const partnersCategoryTable = supabase.from(PARTNERS_CATEGORIES_TABLE)

const partnerCategoryPayload = {
    partner_id: '',
    category_id: '',
}

export const apiAddPartnersCategories = async (payload) => {
    const addPartnerCategoryQuery = partnersCategoryTable.upsert(payload, {
        onConflict: ['id'],
    })
    const addPartnerCategoryData = await executeQuery(addPartnerCategoryQuery)
    return addPartnerCategoryData
}

export const apiDeletePartnerCategories = async (id) => {
    const deletePartnerCategoriesTable = partnersCategoryTable
        .delete()
        .eq('id', id)
    const deletePartnerCategoriesData = await executeQuery(
        deletePartnerCategoriesTable
    )
    return deletePartnerCategoriesData
}
