import { USERS_IMAGES_FOLDER } from 'constants/supabase.contant'
import ApiService from './ApiService'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

export async function apiGetPipeLineList(params = {}) {
    return ApiService.fetchData({
        url: '/organization/department/show',
        method: 'get',
        params,
    })
}

export async function apiGetWorkLocationList(data) {
    return ApiService.fetchData({
        url: '/organization/update-setting',
        method: 'post',
        data,
    })
}

export const apiHandleUploadFile = async (
    imageFolder = USERS_IMAGES_FOLDER,
    file,
    location = 'images'
) => {
    const timestamp = Date.now()
    const fileName = `${timestamp}_${file.name}`
    const imageUploadQuery = supabase.storage
        .from(imageFolder)
        .upload(`${location}/${fileName}`, file)
    const uploadedImageData = await executeQuery(imageUploadQuery)
    uploadedImageData.data = {
        ...uploadedImageData.data,
        url: `${process.env.REACT_APP_SUPABASE_URL}/storage/v1/object/public/${uploadedImageData.data?.fullPath}`,
    }
    return uploadedImageData
}
