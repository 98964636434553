import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    apiGetMemberships,
    getAllMembershipsApi,
    getAllPlansApi,
    getMembershipsStatsApi,
} from 'services/MembershipService'

export const initialMembersTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    plans: [],

    filterColumns: ['name'],
    sort: {
        order: 'desc',
        key: 'created_at',
    },
}

export const initialState = {
    memberships: [],
    stats: {
        total_users: 0,
        total_subscribed_users: 0,
        total_active_subscriptions: 0,
        total_subscribe_this_month: 0,
    },
    tableData: initialMembersTableData,
}

export const getMemberships = createAsyncThunk(
    'get/memberships',
    async (payload) => {
        const { data, error, message, count } = await apiGetMemberships(payload)
        if (error) throw new Error(message)
        return {
            data: [...data],
            count,
        }
    }
)

export const getAllPlans = createAsyncThunk('get/all-plans', async () => {
    const { data, error, message, count } = await getAllPlansApi()
    if (error) throw new Error(message)
    return {
        data,
        count,
    }
})

export const getMembershipStats = createAsyncThunk(
    'get/membership-stats',
    async () => {
        const { data, error, message } = await getMembershipsStatsApi()
        if (error) throw new Error(message)
        return data
    }
)

export const membershipSlice = createSlice({
    name: 'memberships',
    initialState,
    reducers: {
        setMemberships: (state, data) => {
            state.memberships = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        resetTableData: (state) => {
            state.tableData = initialMembersTableData
        },
    },
    extraReducers: {
        [getMemberships.pending]: (state) => {
            state.membershipsFetchLoading = true
            state.membershipsFetchError = ''
        },
        [getMemberships.fulfilled]: (state, { payload }) => {
            state.membershipsFetchLoading = false
            state.membershipsFetchError = ''
            state.memberships = payload?.data
            state.tableData.total = payload?.count
        },
        [getMemberships.rejected]: (state, action) => {
            state.membershipsFetchLoading = false
            state.membershipsFetchError = action.payload ?? action.error
        },

        [getAllPlans.pending]: (state) => {
            state.allPlansFetchLoading = true
            state.allPlansFetchError = ''
        },
        [getAllPlans.fulfilled]: (state, { payload }) => {
            state.allPlansFetchLoading = false
            state.allPlansFetchError = ''
            state.plans = payload?.data
        },
        [getAllPlans.rejected]: (state, action) => {
            state.allPlansFetchLoading = false
            state.allPlansFetchError = action.payload ?? action.error
        },

        [getMembershipStats.pending]: (state) => {
            state.membershipStatsFetchLoading = true
            state.membershipStatsFetchError = ''
        },
        [getMembershipStats.fulfilled]: (state, { payload }) => {
            state.membershipStatsFetchLoading = false
            state.membershipStatsFetchError = ''
            state.stats = payload
        },
        [getMembershipStats.rejected]: (state, action) => {
            state.membershipStatsFetchLoading = false
            state.membershipStatsFetchError = action.payload ?? action.error
        },
    },
})

export const membershipActions = membershipSlice.actions

export const membershipSelector = (state) => state.memberships

export default membershipSlice.reducer
