import { USERS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const passwordResetURL = window.location.origin + '/reset-password'

export async function apiSignIn(payload) {
    const signInQuery = supabase.auth.signInWithPassword(payload)
    const signInData = await executeQuery(signInQuery)
    return signInData
}

export async function apiGetUserSession(token) {
    const getSessionQuery = supabase.auth.getUser(token)
    const userSessionData = executeQuery(getSessionQuery)
    return userSessionData
}

export async function apiSignOut() {
    const { error } = await supabase.auth.signOut()
    if (error) throw error
    return 'signout successfully'
}

export async function apiForgotPassword(email) {
    const forgotPasswordQuery = supabase.auth.resetPasswordForEmail(email, {
        redirectTo: passwordResetURL,
    })
    const forgotPasswordData = await executeQuery(forgotPasswordQuery)
    return forgotPasswordData
}

export async function apiResetPassword(newPassword) {
    const resetPasswordQuery = supabase.auth.updateUser({
        password: newPassword,
    })

    const resetPasswordData = await executeQuery(resetPasswordQuery)
    return resetPasswordData
}

export async function apiGetUserData(userId) {
    const getUserDataQuery = await supabase
        .from(USERS_TABLE)
        .select('*')
        .eq('user_id', userId)
        .single()

    const userData = await executeQuery(getUserDataQuery)
    return userData
}

export const apiAddAdmin = async (payload) => {
    const { name, email, password, phone, role } = payload
    const addAdminQuery = supabase.auth.signUp({ email, password })
    const adminData = await executeQuery(addAdminQuery)
    const addAdminInformation = await supabase.from(USERS_TABLE).insert({
        email,
        role,
        phone,
        name,
        user_id: adminData.data.user.id,
    })
}

export const apiAddPartner = async (payload) => {
    const { name, email, password, phone } = payload
    const addAdminQuery = supabase.auth.signUp({ email, password })
    const partnerData = await executeQuery(addAdminQuery)
    if (partnerData.error) {
        throw partnerData.error
    }
    const addPartnerInInformation = await supabase.from(USERS_TABLE).insert({
        email,
        role: 'partner',
        phone,
        name,
        user_id: partnerData.data.user.id,
    })

    return addPartnerInInformation
}
