import { mimeTypeMap } from 'constants/app.constant'
import { format, isValid, parseISO } from 'date-fns'
// import pdfToText from 'react-pdftotext'

export function formatTime(isoDateString, fmt = 'MMM dd yyyy, h:mm a') {
    if (!isoDateString) return ''
    const date = parseISO(isoDateString)
    const formattedDate = format(date, fmt)
    return formattedDate
}

export const formatDate = (dateString, dateFormat) => {
    const date = new Date(dateString)
    if (!isValid(date)) {
        console.error('Error parsing date: Invalid date string', dateString)
        return ''
    }
    return format(date, dateFormat)
}

export const modifyDateByDays = (endDate, days) => {
    const date = new Date(endDate)
    date.setDate(date.getDate() + days)
    return date
}

export const parseBooleanVal = (val) => {
    if (val === 'true' || val === true || val === '1' || val === 1) return true
    return false
}

export const dateFormatMilliSeconds = (
    milliseconds,
    dateFormat = 'MMM dd, yyyy'
) => {
    const start = new Date(milliseconds)
    const formattedStartDate = format(start, dateFormat)
    return formattedStartDate
}

export const truncateText = (text, maxLength) => {
    if (!text) return
    if (text.length <= maxLength) {
        return text
    }
    return text.substring(0, maxLength) + '...'
}

export function myTimeZone() {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
}

export const fetchFileFromUrl = async (url, name) => {
    try {
        const response = await fetch(url)
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`)
        }

        const blob = await response.blob()
        const fileExtension = getFileExtension(name)
        const fileType =
            mimeTypeMap[fileExtension] || 'application/octet-stream'
        const file = new File([blob], name, { type: fileType })
        return file
    } catch (error) {
        console.error('Error fetching file from URL:', error)
        throw error
    }
}

export const getFileNameFromUrl = (url = '') => {
    const urlObj = new URL(url, window.location.origin)
    return urlObj.pathname.split('/').pop()
}

export const getFileExtension = (fileName = '') => {
    const parts = fileName.split('.')
    return parts.length > 1 ? parts.pop() : ''
}

export function convertCentsToShiling(cents) {
    return (cents / 100).toFixed(2)
}

export const getNameProfile = (first, last, background = null) => {
    if (!first || !last) return ''
    return `https://ui-avatars.com/api/?name=${first}+${last}&background=${
        background ? background : 'random'
    }`
}

export const capitalizeFirstLetter = (string) => {
    if (!string || string === undefined) return ''
    return string.charAt(0).toUpperCase() + string.slice(1)
}

export const capitalizeAllFirstLetters = (string) => {
    if (!string || string === undefined) return ''
    return string.replace(/(^\w{1})|(\s+\w{1})/g, (letter) =>
        letter.toUpperCase()
    )
}

export const hasBlankSpaces = (str) => {
    if (!str) return false
    return str.match(/^\s+$/) !== null
}
