import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import {
    getAllPartnerApi,
    getAllPartnerOptionsApi,
    getPartnersStatsApi,
} from 'services/PartnerService'

export const initialPartnerTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    filterColumn: ['title'],
    query: '',
    sort: {
        order: 'desc',
        key: 'created_at',
    },
}

export const initialState = {
    partners: [],
    stats: {
        month_count: 0,
        total_count: 0,
        inactive_count: 0,
    },
    tableData: initialPartnerTableData,
    allPartnersOptions: [],
}

export const getAllPartners = createAsyncThunk(
    'get/all-partners',
    async (payload) => {
        const { data, error, message, count } = await getAllPartnerApi(payload)
        if (error) throw new Error(message)
        return {
            data: [...data],
            count,
        }
    }
)

export const getAllPartnersOptions = createAsyncThunk(
    'get/all-partners-options',
    async (payload) => {
        const { data, error, message, count } = await getAllPartnerOptionsApi(
            payload
        )
        if (error) throw new Error(message)
        return {
            data: [...data],
            count,
        }
    }
)

export const getPartnerStats = createAsyncThunk(
    'get/partner-stats',
    async () => {
        const { data, error, message } = await getPartnersStatsApi()
        if (error) throw new Error(message)
        // console.log('dadadadad', data)
        return data
    }
)

export const partnerSlice = createSlice({
    name: 'partner',
    initialState,
    reducers: {
        setPartners: (state, data) => {
            state.partners = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },

        resetTableData: (state) => {
            state.tableData = initialPartnerTableData
        },
    },
    extraReducers: {
        [getAllPartners.pending]: (state) => {
            state.partnersFetchLoading = true
            state.partnersFetchError = ''
        },
        [getAllPartners.fulfilled]: (state, { payload }) => {
            state.partnersFetchError = ''
            state.partners = payload?.data
            state.tableData.total = payload?.count
            state.partnersFetchLoading = false
        },
        [getAllPartners.rejected]: (state, action) => {
            state.partnersFetchLoading = false
            state.partnersFetchError = action.payload ?? action.error
        },

        [getPartnerStats.pending]: (state) => {
            state.partnersStatLoading = true
            state.partnersStatError = ''
        },
        [getPartnerStats.fulfilled]: (state, { payload }) => {
            state.partnersStatError = ''
            state.stats = payload
            state.partnersStatLoading = false
        },
        [getPartnerStats.rejected]: (state, action) => {
            state.partnersStatLoading = false
            state.partnersStatError = action.payload ?? action.error
        },

        [getAllPartnersOptions.pending]: (state) => {
            state.allPartnersOptionsLoading = true
            state.allPartnersOptionsError = ''
        },
        [getAllPartnersOptions.fulfilled]: (state, { payload }) => {
            state.allPartnersOptionsError = ''
            state.allPartnersOptions = payload.data || []
            state.allPartnersOptionsLoading = false
        },
        [getAllPartnersOptions.rejected]: (state, action) => {
            state.allPartnersOptionsLoading = false
            state.allPartnersOptionsError = action.payload ?? action.error
        },
    },
})

export const partnerAction = partnerSlice.actions

export const partnerSelector = (state) => state.partner

export default partnerSlice.reducer
