import { PARTNER_SOCIALS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const partnerSocialsTable = supabase.from(PARTNER_SOCIALS_TABLE)

const socialsPayload = [
    {
        type: 'facebook| instagram| twitter| website	',
        url: '',
        partner_id: '',
    },
]

export const apiAddPartnerSocials = async (payload) => {
    const addPartnerSocialsQuery = partnerSocialsTable.insert(payload)
    const addPartnerSocialData = await executeQuery(addPartnerSocialsQuery)
    return addPartnerSocialData
}
