import { GIVEAWAYS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const createGiveawaysTable = () => supabase.from(GIVEAWAYS_TABLE)

export const addGiveawayApi = async (payload) => {
    const giveawaysTable = createGiveawaysTable()
    const addGiveawayQuery = giveawaysTable.insert(payload).select()
    const giveAwayData = await executeQuery(addGiveawayQuery)
    return giveAwayData
}

export const editGiveawayApi = async (giveawayId, payload) => {
    const giveawaysTable = createGiveawaysTable()
    const editGiveawayQuery = giveawaysTable
        .update(payload)
        .eq('id', giveawayId)
        .select()
        .single()
    const giveAwayData = await executeQuery(editGiveawayQuery)
    return giveAwayData
}

export const changeGiveawayStatusApi = async (giveawayId, status) => {
    const giveawaysTable = createGiveawaysTable()
    const changeStatusGiveawayQuery = giveawaysTable
        .update({ status })
        .eq('id', giveawayId)
        .select()
        .single()
    const giveAwayData = await executeQuery(changeStatusGiveawayQuery)
    return giveAwayData
}

export const deleteGiveawayApi = async (giveawayId) => {
    const giveawaysTable = createGiveawaysTable()
    const deleteGiveawayQuery = giveawaysTable
        .update({ is_delete: true })
        .eq('id', giveawayId)
        .select()
    const giveAwayData = await executeQuery(deleteGiveawayQuery)
    return giveAwayData
}

// export const getGiveawaysApi = async (payload) => {
//     const { pageSize = 10, pageIndex = 1, sort } = payload
//     const from = (pageIndex - 1) * pageSize
//     const to = from + pageSize - 1

//     const giveawaysTable = createGiveawaysTable()
//     let giveawayQuery = giveawaysTable
//         .select('*', { count: 'exact' })
//         .eq('is_delete', false)
//         .order('created_at', { ascending: false })
//         .range(from, to)

//     if (sort && sort.key !== '' && sort.key !== null) {
//         giveawayQuery = giveawayQuery.order(sort.key, {
//             ascending: sort.order === 'asc' ? true : false,
//         })
//     }

//     const result = await executeQuery(giveawayQuery)
//     return result
// }

export const getGiveawaysApi = async (payload) => {
    const giveawaysTable = createGiveawaysTable()
    const {
        pageSize = 10,
        pageIndex = 1,
        query = '',
        filterColumns,
        sort,
    } = payload

    const filters = payload?.filters || []

    const from = (pageIndex - 1) * pageSize
    const to = from + pageSize - 1

    let giveawayQuery = giveawaysTable
        .select('*', { count: 'exact' })
        .eq('is_delete', false)

    if (sort && sort.key !== '' && sort.key !== null) {
        giveawayQuery = giveawayQuery.order(sort.key, {
            ascending: sort.order === 'asc' ? true : false,
        })
    }

    if (query) {
        filterColumns.forEach((column) => {
            giveawayQuery = giveawayQuery.ilike(column, `%${query}%`)
        })
    }

    if (Array.isArray(filters) && filters.length > 0) {
        filters.forEach(({ key, value }) => {
            if (value != null) {
                giveawayQuery = giveawayQuery.eq(key, value)
            }
        })
    }

    giveawayQuery = giveawayQuery.range(from, to)

    const result = await executeQuery(giveawayQuery)

    if (result.error) {
        throw result.error
    }

    return {
        data: result.data,
        count: result.count,
        message: 'Giveaway Data Fetched Successfully',
    }
}

export const getGiveawayByUUIDApi = async (uuid) => {
    const giveawaysTable = createGiveawaysTable()
    const fetchDataQuery = giveawaysTable
        .select('*')
        .eq('id', uuid)
        .eq('is_delete', false)
        .single()
    const result = await executeQuery(fetchDataQuery)
    return result
}

export const getGiveawaysStatsApi = async () => {
    const { data, error } = await supabase.rpc('get_giveaway_stats')

    return { data, error, message: '' }
}
