import { OFFERS_MEMBERSHIPS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const offerMembershipsTable = supabase.from(OFFERS_MEMBERSHIPS_TABLE)

export const apiAddOfferMemberships = async (payload) => {
    const addOfferMembershipsQuery = offerMembershipsTable.upsert(payload, {
        onConflict: ['id'],
    })
    const addOfferMembershipsData = await executeQuery(addOfferMembershipsQuery)
    return addOfferMembershipsData
}

export const deleteOfferMembership = async (id) => {
    const deleteMembershipQuery = offerMembershipsTable.delete().eq('id', id)
    const deleteMembershipData = await executeQuery(deleteMembershipQuery)
    return deleteMembershipData
}
