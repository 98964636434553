import { PARTNERS_TABLE } from 'constants/supabase.contant'
import supabase from 'supabase'
import { executeQuery } from 'utils/executeQuery'

const createPartnersTable = () => supabase.from(PARTNERS_TABLE)

const preparePSocials = (payload, prevSocials) => {
    const p_socials = []

    const socialTypes = ['facebook', 'instagram', 'twitter', 'website']

    socialTypes.forEach((type) => {
        const existingSocial = prevSocials.find((x) => x.type === type)
        const payloadUrl = payload[type]
        if (payloadUrl) {
            if (existingSocial && existingSocial.url !== payloadUrl) {
                p_socials.push({
                    id: existingSocial.id, // Include id for updating
                    type: type,
                    url: payloadUrl,
                })
            } else if (!existingSocial) {
                p_socials.push({
                    type: type,
                    url: payloadUrl,
                })
            } else if (existingSocial && existingSocial.url === payloadUrl) {
                p_socials.push({
                    id: existingSocial.id,
                    type: type,
                    url: payloadUrl,
                })
            }
        }
    })

    return p_socials
}

export const addPartnerApi = async (payload) => {
    const p_socials = []
    if (payload.facebook) {
        p_socials.push({ type: 'facebook', url: payload.facebook })
    }
    if (payload.instagram) {
        p_socials.push({ type: 'instagram', url: payload.instagram })
    }
    if (payload.twitter) {
        p_socials.push({ type: 'twitter', url: payload.twitter })
    }
    if (payload.website) {
        p_socials.push({ type: 'website', url: payload.website })
    }

    const payloadData = {
        p_image: payload.partner_photo,
        p_name: payload.partner_name,
        p_email: payload.partner_email,
        p_phone: payload.partner_phone,
        p_term_condition: payload.term_condition,
        p_documents: payload.documents,
        p_brand_page: payload.brand_page,
        p_socials,
        p_categories: payload.category?.map((x) => x.value),
        p_description: payload.partner_description,
    }

    const { data, error } = await supabase.rpc('insert_partner', payloadData)

    return { data, error, message: '' }
}

export const getAllPartnerApi = async (payload = {}) => {
    try {
        const partnerTable = supabase.from(PARTNERS_TABLE)
        const {
            pageSize = 10,
            pageIndex = 1,
            query = '',
            filterColumns = [],
            sort = { key: 'created_at', order: 'desc' },
            filters = [],
        } = payload

        const from = (pageIndex - 1) * pageSize
        const to = from + pageSize - 1

        const selectString = `
                    *,
                    categories:partners_categories(
                    partner_id,
                    category:categories(*)
                    ),
                    socials:partner_socials(*) `

        let queryBuilder = partnerTable
            .select(selectString, { count: 'exact' })
            .eq('is_delete', false)

        if (Array.isArray(filters) && filters.length > 0) {
            filters.forEach(({ key, value }) => {
                if (value != null) {
                    queryBuilder = queryBuilder.eq(key, value)
                }
            })
        }

        if (sort.key && sort.order) {
            const ascending = sort.order.toLowerCase() === 'asc'
            queryBuilder = queryBuilder.order(sort.key, { ascending })
        }

        if (query && Array.isArray(filterColumns) && filterColumns.length > 0) {
            filterColumns.forEach((column) => {
                queryBuilder = queryBuilder.ilike(column, `%${query}%`)
            })
        }

        queryBuilder = queryBuilder.range(from, to)

        const { data, error, count } = await executeQuery(queryBuilder)

        if (error) {
            throw error
        }

        return {
            data: data,
            count,
            message: 'Partners Data fetched successfully.',
        }
    } catch (error) {
        console.error('Error fetching partners:', error)
        throw error
    }
}

export const getAllPartnerOptionsApi = async (payload = {}) => {
    try {
        const partnerTable = supabase.from(PARTNERS_TABLE)
        const queryBuilder = partnerTable.select(`*`).eq('is_delete', false)
        const { data, error, count } = await executeQuery(queryBuilder)

        if (error) {
            throw error
        }

        return {
            data: data?.map((o) => ({ ...o, value: o.id, label: o.name })),
            count,
            message: 'Partners Data fetched successfully.',
        }
    } catch (error) {
        console.error('Error fetching partners:', error)
        throw error
    }
}

export const getPartnerByUUIDApi = async (uuid) => {
    const partnersTable = createPartnersTable()
    const fetchDataQuery = partnersTable
        .select(
            `*, categories:partners_categories(
                    partner_id,
                    category:categories(*)
                    ),
                    socials:partner_socials(*)`
        )
        .eq('id', uuid)
        .eq('is_delete', false)
        .single()
    const result = await executeQuery(fetchDataQuery)
    return result
}

export const editPartnerByUUIDApi = async (payload, originalData) => {
    try {
        const {
            partner_id,
            partner_photo,
            partner_name,
            partner_email,
            partner_phone,
            partner_description,
            term_condition,
            documents,
            brand_page,
            category,
        } = payload

        const payloadData = {
            p_partner_id: partner_id,
            p_image: partner_photo,
            p_name: partner_name,
            p_email: partner_email,
            p_phone: partner_phone,
            p_term_condition: term_condition,
            p_description: partner_description,
            p_documents: documents,
            p_brand_page: brand_page,
            p_socials: preparePSocials(
                payload,
                originalData?.partner?.socials || []
            ),
            p_categories: category?.map((c) => c.value),
        }
        const { data, error } = await supabase.rpc('edit_partner', payloadData)

        if (error) {
            throw error
        }
        // console.log('data', data)
        return {
            data: { ...data?.offer, partner: { ...data?.partner } },
            message: 'Partner updated successfully.',
        }
    } catch (error) {
        console.error('Error updating partner offer:', error.message || error)
        throw error
    }
}

export const getPartnersStatsApi = async () => {
    try {
        const { data, error } = await supabase.rpc('get_partner_stats')
        return { data, error, message: '' }
    } catch (error) {
        console.error('Error in getPartnersStatsApi:', error)
        throw error
    }
}

export const deletePartnerApi = async (partnerId) => {
    const partnerTable = createPartnersTable()
    const deletePartnerQuery = partnerTable
        .update({ is_delete: true })
        .eq('id', partnerId)
        .select()
    const partnerData = await executeQuery(deletePartnerQuery)
    return partnerData
}

export const changePartnerStatusApi = async (partnerId, status) => {
    const partnersTable = createPartnersTable()
    const changeStatusPartnerQuery = partnersTable
        .update({ status })
        .eq('id', partnerId)
        .select()
        .single()
    const partnerData = await executeQuery(changeStatusPartnerQuery)
    return partnerData
}
