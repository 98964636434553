import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiGetUserSession, apiSignIn, apiSignOut } from 'services/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'
import { REDIRECT_URL_KEY } from 'constants/app.constant'
import { useNavigate } from 'react-router-dom'
import useQuery from './useQuery'
import { ADMIN_PREFIX_PATH } from 'constants/route.constant'
import { apiGetMemberByAuthId } from 'services/MembersService'
import { apiAddNewPartner } from 'services/PartnersService'

function useAuth() {
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const query = useQuery()

    const { token, signedIn } = useSelector((state) => state.auth.session)

    const signIn = async (values) => {
        try {
            const { data, error, message } = await apiSignIn(values)
            if (error) {
                return {
                    status: 'failed',
                    message: message,
                }
            }
            if (data) {
                const {
                    session: { access_token },
                    user: { id },
                } = data

                const {
                    data: userData,
                    error: userDataError,
                    message: userErrorMessage,
                } = await apiGetMemberByAuthId(id)
                if (
                    userDataError ||
                    userErrorMessage?.includes('No record found')
                ) {
                    return {
                        status: 'failed',
                        message: userErrorMessage?.includes('No record found')
                            ? 'Invalid Credentials'
                            : userErrorMessage,
                    }
                } else {
                    dispatch(onSignInSuccess(access_token))
                    dispatch(setUser(userData))
                    const redirectUrl = query.get(REDIRECT_URL_KEY)
                    navigate(
                        redirectUrl
                            ? redirectUrl
                            : `${ADMIN_PREFIX_PATH}/dashboard`
                    )
                }

                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.message || errors.toString(),
            }
        }
    }

    // const signUp = async (values) => {
    //     try {
    //         const resp = await apiAddNewPartner(values)
    //         if (resp.data) {
    //             const { token } = resp.data
    //             dispatch(onSignInSuccess(token))
    //             if (resp.data) {
    //                 dispatch(setUser(resp.data))
    //             }
    //             const redirectUrl = query.get(REDIRECT_URL_KEY)
    //             navigate(
    //                 redirectUrl ? redirectUrl : `${ADMIN_PREFIX_PATH}/dashboard`
    //             )
    //             return {
    //                 status: 'success',
    //                 message: '',
    //             }
    //         }
    //     } catch (errors) {
    //         return {
    //             status: 'failed',
    //             message: errors?.response?.data?.message || errors.toString(),
    //         }
    //     }
    // }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        await apiSignOut()
        handleSignOut()
    }

    const checkUserSession = async () => {
        const { data, error, message } = await apiGetUserSession(token)
        if (error && message.toLocaleLowerCase().includes('invalid jwt')) {
            return false
        } else {
            return true
        }
    }

    return {
        authenticated: token && signedIn,
        signIn,
        // signUp,
        signOut,
        checkUserSession,
    }
}

export default useAuth
