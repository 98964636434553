import React, { useEffect, useState } from 'react'
import { VscFilePdf, VscFileZip, VscFile } from 'react-icons/vsc'
import { fetchFileFromUrl, getFileNameFromUrl } from 'utils/utils'

const BYTE = 1000
const getKB = (bytes) => Math.round(bytes / BYTE)

const FileIcon = ({ children }) => {
    return <span className="text-4xl">{children}</span>
}

const FileItem = (props) => {
    const { file, children } = props
    const [currentFile, setCurrentFile] = useState('')
    useEffect(() => {
        const handleFileConversion = async () => {
            if (!(file instanceof File)) {
                const fileObj = file?.file ? file.file : file

                const fileName = getFileNameFromUrl(fileObj)
                // console.log
                const fileFromUrl = await fetchFileFromUrl(fileObj, fileName)
                setCurrentFile(fileFromUrl)
            } else {
                setCurrentFile(file)
            }
        }

        handleFileConversion()
    }, [file])

    const type = currentFile instanceof File ? currentFile.type : ''
    const name = currentFile instanceof File ? currentFile.name : ''
    const size = currentFile instanceof File ? currentFile.size : 0

    const renderThumbnail = () => {
        const isImageFile = type.split('/')[0] === 'image'

        if (isImageFile) {
            return (
                <img
                    className="upload-file-image"
                    src={
                        !(file instanceof File) &&
                        (file?.includes('http') || file?.includes('https'))
                            ? file
                            : URL.createObjectURL(currentFile)
                    }
                    alt={`file preview ${name}`}
                />
            )
        }

        if (type === 'application/zip') {
            return (
                <FileIcon>
                    <VscFileZip />
                </FileIcon>
            )
        }

        if (type === 'application/pdf') {
            return (
                <FileIcon>
                    <VscFilePdf />
                </FileIcon>
            )
        }

        return (
            <FileIcon>
                <VscFile />
            </FileIcon>
        )
    }

    return (
        <div className="upload-file">
            <div className="flex">
                <div className="upload-file-thumbnail">{renderThumbnail()}</div>
                <div className="upload-file-info">
                    <h6 className="upload-file-name">{name}</h6>
                    <span className="upload-file-size">{getKB(size)} kb</span>
                </div>
            </div>
            {children}
        </div>
    )
}

export default FileItem
