import { createSlice } from '@reduxjs/toolkit'

export const initialState = {
    email: '',
    authority: [],
}

export const userSlice = createSlice({
    name: 'auth/user',
    initialState,
    reducers: {
        setUser: (_, action) => {
            return {
                ...action.payload,
            }
        },
        userLoggedOut: () => initialState,
    },
})

export const { setUser } = userSlice.actions

export const userSelector = (state) => state.auth

export default userSlice.reducer
