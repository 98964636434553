export const APP_NAME = 'Elstar'
export const PERSIST_STORE_NAME = 'admin'
export const REDIRECT_URL_KEY = 'redirectUrl'

export const DELETED_FROM = {
    DEPARTMENT: 'department',
    ASSET_CATEGORY: 'asset_category',
    WORK_LOCATION: 'work_location',
    BENEFIT_PACKAGE: 'benefit',
    SKILL_SET: 'skill_set',
    PIPELINE_STAGE: 'pipeline_stage',
    ONBOARDING: 'onboarding',
    POLICY_CATEGORY: 'policy_category',
}

export const LOADER_STATUS = {
    NA: -1,
    PENDING: 1,
    COMPLETE: 0,
}

export const mimeTypeMap = {
    // Image Formats
    jpg: 'image/jpeg',
    jpeg: 'image/jpeg',
    png: 'image/png',
    gif: 'image/gif',
    bmp: 'image/bmp',
    webp: 'image/webp',
    tiff: 'image/tiff',
    tif: 'image/tiff',
    svg: 'image/svg+xml',

    // Document Formats
    pdf: 'application/pdf',
    doc: 'application/msword',
    docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    ppt: 'application/vnd.ms-powerpoint',
    pptx: 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    txt: 'text/plain',
    csv: 'text/csv',

    // Archive Formats
    zip: 'application/zip',
    tar: 'application/x-tar',
    gz: 'application/gzip',
    bz: 'application/x-bzip',
    bz2: 'application/x-bzip2',
    '7z': 'application/x-7z-compressed',

    // Audio Formats
    mp3: 'audio/mpeg',
    wav: 'audio/wav',
    ogg: 'audio/ogg',
    aac: 'audio/aac',
    m4a: 'audio/x-m4a',

    // Video Formats
    mp4: 'video/mp4',
    avi: 'video/x-msvideo',
    mkv: 'video/x-matroska',
    mov: 'video/quicktime',
    wmv: 'video/x-ms-wmv',
    flv: 'video/x-flv',

    // Other Formats
    html: 'text/html',
    htm: 'text/html',
    json: 'application/json',
    xml: 'application/xml',
    md: 'text/markdown',

    // Miscellaneous
    exe: 'application/octet-stream',
    dll: 'application/octet-stream',
    bin: 'application/octet-stream',
}
