import React from 'react'
import PropTypes from 'prop-types'
import { Table, Skeleton } from 'components/ui'

const { Tr, Td, TBody } = Table

const TableRowSkeleton = (props) => {
    const { columns, rows, avatarInColumns = [0], avatarProps } = props
    return (
        <TBody>
            {Array.from(new Array(rows), (_, i) => i).map((row) => (
                <Tr key={`row-${row}`}>
                    {Array.from(new Array(columns), (_, i) => i).map((col) => (
                        <Td key={`col-${col}`}>
                            {avatarInColumns.includes(col) ? (
                                <div className="flex items-center gap-2">
                                    <Skeleton
                                        variant="circle"
                                        width={42}
                                        height={42}
                                        {...avatarProps}
                                    />
                                    <div className="flex flex-col flex-grow gap-2">
                                        <Skeleton  />
                                        <Skeleton  />
                                    </div>
                                </div>
                            ) : (
                                <Skeleton  />
                            )}
                        </Td>
                    ))}
                </Tr>
            ))}
        </TBody>
    )
}

TableRowSkeleton.propTypes = {
    columns: PropTypes.number,
    rows: PropTypes.number,
    avatarInColumns: PropTypes.arrayOf(PropTypes.number),
}

TableRowSkeleton.defaultProps = {
    columns: 1,
    rows: 10,
    avatarInColumns: [],
}

export default TableRowSkeleton