import { countryList } from "constants/countries.constant"
export const makeDynamicFields = ({
	pick,
	drop = ['name', 'details'],
	rawData,
	majorKey,
	path,
	isEmpty,
}) => {
	const [pFirst, pSecond] = pick
	const [dFirst, dSecond] = drop
	if (rawData.length === 0 && majorKey && isEmpty) {
		return { [majorKey]: [{ name: '', details: '' }] }
	}

	if (rawData.length === 0 && isEmpty) {
		return [{ name: '', details: '' }]
	}

	if (majorKey) {
		return {
			[majorKey]: rawData.map((item) => ({
				[dFirst]: item[pFirst],
				[dSecond]: item[pSecond],
				uuid: item.uuid,
				path,
			})),
		}
	}

	return rawData.map((item) => ({
		[dFirst]: item[pFirst],
		[dSecond]: item[pSecond],
		uuid: item.uuid,
		photo: item.photo,
		job_title: item.job_title,
		path,
	}))
}


export const bytesToMB = (bytes, decimals = 2) => {
	if (bytes === 0) {
		return '0 Bytes'
	}

	const k = 1024
	const dm = decimals < 0 ? 0 : decimals
	const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
	const i = Math.floor(Math.log(bytes) / Math.log(k))

	return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}


export const getCountryNameById = (id) => {
    const country = countryList.find(country => country.value == id);
    return country ? country.label : 'Country not found';
};


