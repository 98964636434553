import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { getGiveawaysApi, getGiveawaysStatsApi } from 'services/GiveawayService'

export const initialGiveawayTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    filterColumn: ['title'],
    query: '',
    sort: {
        order: 'desc',
        key: 'created_at',
    },
}

export const initialState = {
    giveaways: [],
    stats: {
        month_count: 0,
        total_count: 0,
        total_eligible_tickets: 0,
    },
    tableData: initialGiveawayTableData,
}

export const getAllGiveaways = createAsyncThunk(
    'get/all-giveaways',
    async (payload) => {
        const { data, error, message, count } = await getGiveawaysApi(payload)
        if (error) throw new Error(message)
        return {
            data: [...data],
            count,
        }
    }
)

export const getGiveawayStats = createAsyncThunk(
    'get/giveaway-stats',
    async () => {
        const { data, error, message } = await getGiveawaysStatsApi()
        if (error) throw new Error(message)
        return data
    }
)

export const giveawaySlice = createSlice({
    name: 'giveaway',
    initialState,
    reducers: {
        setGiveaway: (state, data) => {
            state.giveaway = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },

        resetTableData: (state) => {
            state.tableData = initialGiveawayTableData
        },
    },
    extraReducers: {
        [getAllGiveaways.pending]: (state) => {
            state.giveawaysFetchLoading = true
            state.giveawaysError = ''
        },
        [getAllGiveaways.fulfilled]: (state, { payload }) => {
            state.giveawaysError = ''
            state.giveaways = [...payload?.data]
            state.tableData.total = payload?.count
            state.giveawaysFetchLoading = false
        },
        [getAllGiveaways.rejected]: (state, action) => {
            state.giveawaysFetchLoading = false
            state.giveawaysError = action.payload ?? action.error
        },

        [getGiveawayStats.pending]: (state) => {
            state.giveawayStatLoading = true
            state.giveawayStatError = ''
        },
        [getGiveawayStats.fulfilled]: (state, { payload }) => {
            state.giveawayStatError = ''
            state.stats = payload
            state.giveawayStatLoading = false
        },
        [getGiveawayStats.rejected]: (state, action) => {
            state.giveawayStatLoading = false
            state.giveawayStatError = action.payload ?? action.error
        },
    },
})

export const giveawayAction = giveawaySlice.actions

export const giveawaySelector = (state) => state.giveaway

export default giveawaySlice.reducer
