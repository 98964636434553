import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCategories } from 'services/CategoriesService'
import { getAllDealApi, getDealsStatsApi } from 'services/DealsService'

export const initialDealTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    filterColumn: ['partner.name'],
    query: '',
    sort: {
        order: 'desc',
        key: 'created_at',
    },
    filters: [],
}

export const initialState = {
    deals: [],
    dealsStats: {
        deals_this_month: 0,
        total_deals: 0,
        partners_this_month: 0,
        total_inactive_deals: 0,
        total_partners: 0,
    },
    partnerCategories: [],
    tableData: initialDealTableData,
}

export const getAllDeals = createAsyncThunk(
    'get/all-deals',
    async (payload) => {
        const { data, error, message, count } = await getAllDealApi(payload)
        if (error) throw new Error(message)
        return {
            data: [
                ...data?.map((x) => ({
                    ...x,
                    memberships: x.memberships?.map((i) => ({
                        offer_id: i?.offer_id,
                        ...i?.memberships,
                    })),
                    partner: {
                        ...x.partner,
                        categories: x.partner?.categories?.map((i) => ({
                            partner_id: i?.partner_id,
                            ...i?.category,
                        })),
                    },
                })),
            ],
            count,
        }
    }
)

export const getAllPartnerCategories = createAsyncThunk(
    'get/all-partner-categories',
    async () => {
        const { data, error, message, count } = await apiGetCategories()
        if (error) throw new Error(message)
        return {
            data: [
                ...data?.map((x) => ({
                    value: x.id,
                    label: x.name,
                })),
            ],
            count,
        }
    }
)

export const getDealsStats = createAsyncThunk('get/deals-stats', async () => {
    const data = await getDealsStatsApi()
    return data
})

export const dealSlice = createSlice({
    name: 'deal',
    initialState,
    reducers: {
        setDeals: (state, data) => {
            state.deals = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },

        resetTableData: (state) => {
            state.tableData = initialDealTableData
        },
    },
    extraReducers: {
        [getAllDeals.pending]: (state) => {
            state.dealsFetchLoading = true
            state.dealsError = ''
        },
        [getAllDeals.fulfilled]: (state, { payload }) => {
            // console.log('payload', payload)
            state.dealsError = ''
            state.deals = payload?.data || []
            state.tableData.total = payload?.count || 0
            state.dealsFetchLoading = false
        },
        [getAllDeals.rejected]: (state, action) => {
            state.dealsFetchLoading = false
            state.dealsError = action.payload ?? action.error
        },

        [getAllPartnerCategories.pending]: (state) => {
            state.partnerCategoriesFetchLoading = true
            state.partnerCategoriesError = ''
        },
        [getAllPartnerCategories.fulfilled]: (state, { payload }) => {
            state.partnerCategoriesError = ''
            state.partnerCategories = [...payload?.data]
            state.tableData.total = payload?.count
            state.partnerCategoriesFetchLoading = false
        },
        [getAllPartnerCategories.rejected]: (state, action) => {
            state.partnerCategoriesFetchLoading = false
            state.partnerCategoriesError = action.payload ?? action.error
        },

        [getDealsStats.pending]: (state) => {
            state.dealsStatLoading = true
            state.dealsStatError = ''
        },
        [getDealsStats.fulfilled]: (state, { payload }) => {
            state.dealsStatError = ''
            state.dealsStats = payload
            state.dealsStatLoading = false
        },
        [getDealsStats.rejected]: (state, action) => {
            state.dealsStatLoading = false
            state.dealsStatError = action.payload ?? action.error
        },
    },
})

export const dealAction = dealSlice.actions

export const dealSelector = (state) => state.deal

export default dealSlice.reducer
