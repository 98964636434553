const formatResponseObject = (
    error = false,
    errorMessage = '',
    data = null,
    count = 0
) => {
    return {
        error: error,
        message: error ? errorMessage : '',
        data: data,
        count: count,
    }
}

const getErrorMessage = (error) => {
    if (error.code === 'PGRST116') {
        return 'No record found'
    }
    if (error instanceof TypeError) {
        return 'A type error occurred. Please check the input values.'
    }
    if (error instanceof ReferenceError) {
        return 'A reference error occurred. Please ensure all variables are defined.'
    }
    if (error.message.includes('NetworkError')) {
        return 'A network error occurred. Please check your internet connection.'
    }
    if (error.message.includes('timeout')) {
        return 'The request timed out. Please try again.'
    }
    return error.message || 'An unknown error occurred.'
}

const formatResponse = (error, data, count) => {
    if (error) {
        const errorMessage = getErrorMessage(error)
        return formatResponseObject(true, errorMessage, null)
    }
    return formatResponseObject(false, '', data, count || 0)
}

export const executeQuery = async (query) => {
    try {
        const { data, error, count } = await query
        return formatResponse(error, data, count)
    } catch (error) {
        return formatResponse(error, null)
    }
}
