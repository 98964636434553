import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { apiGetCoupons, getCouponsStatsApi } from 'services/CouponService'

export const initialCouponsTableData = {
    total: 0,
    pageIndex: 1,
    pageSize: 10,
    query: '',
    filterColumns: ['code'],
    filters: [],
    sort: {
        order: 'desc',
        key: 'created_at',
    },
}

export const initialState = {
    coupons: [],
    tableData: initialCouponsTableData,
    stats: {
        month_count: 0,
        total_count: 0,
    },
}

export const getCoupons = createAsyncThunk(
    'get/coupons',
    async (payload = {}) => {
        try {
            const { data, error, count } = await apiGetCoupons(payload)
            if (error) throw new Error(error?.message)
            return {
                data: [...data],
                count,
            }
        } catch (err) {
            console.log('errapi', err)
        }
    }
)
export const getCouponStats = createAsyncThunk('get/coupon-stats', async () => {
    const { data, error, message } = await getCouponsStatsApi()
    if (error) throw new Error(message)
    return data
})

export const couponSlice = createSlice({
    name: 'coupons',
    initialState,
    reducers: {
        setCoupons: (state, data) => {
            state.coupons = data
        },
        setTableData: (state, action) => {
            state.tableData = action.payload
        },
        resetTableData: (state) => {
            state.tableData = initialCouponsTableData
        },
    },
    extraReducers: {
        [getCoupons.pending]: (state) => {
            state.couponsFetchLoading = true
            state.couponsFetchError = ''
        },
        [getCoupons.fulfilled]: (state, action) => {
            const { payload } = action
            state.couponsFetchLoading = false
            state.couponsFetchError = ''
            state.coupons = payload?.data || []
            state.tableData.total = payload?.count
        },
        [getCoupons.rejected]: (state, action) => {
            state.couponsFetchLoading = false
            state.couponsFetchError = action.payload ?? action.error
        },

        [getCouponStats.pending]: (state) => {
            state.couponStatLoading = true
            state.couponStatError = ''
        },
        [getCouponStats.fulfilled]: (state, { payload }) => {
            state.couponStatError = ''
            state.stats = payload
            state.couponStatLoading = false
        },
        [getCouponStats.rejected]: (state, action) => {
            state.couponStatLoading = false
            state.couponStatError = action.payload ?? action.error
        },
    },
})

export const couponActions = couponSlice.actions

export const couponSelector = (state) => state.coupon

export default couponSlice.reducer
